h2 {
    display: table;
    clear: both;
}

p {
    display: block;
    display: table;
    clear: both;
}

h3 {
    margin-top:10px;
}

h2 span::before {
    content: attr(data-text);
    background: black;
    padding: 1px 8px;
  }