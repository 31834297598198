body { 
  font-size: 100%; 
  font-size: medium;
}
.logo_img {
  max-width: 43px;
}

.nav-link {
  font-size: large;
}

p {
  font-size: medium;
}

.btn {
  font-size: medium;
}

h3 {
  font-size: large;
}